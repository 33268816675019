import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  formParams(formData) {
    formData = formData || new FormData(this.formTarget);
    const params = new URLSearchParams(formData).toString();
    return params;
  }

  getUrl(url, formData) {
    const newUrl = url + '?' + this.formParams(formData);
    return newUrl;
  }

  downloadPdf() {
    const pdf_url = this.getUrl(this.data.get('pdf-url'));
    const win = window.open(pdf_url, '_blank');
    win.focus();
  }

  async sendEmail() {
    showHideLoading(true);
    const mail_url = this.getUrl(this.data.get('mail-url'));
    const response = await fetch(mail_url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
      },
    });
    let data;
    try {
      data = await response.json();
    } catch (error) {
      data = { message: 'Error al enviar el correo' };
    }
    showHideLoading(false);
    if (response.ok) {
      Swal.fire({
        type: 'success',
        timer: 3000,
        icon: 'success',
        title: data.message,
        showConfirmButton: true,
      });
    } else {
      Swal.fire({
        type: 'error',
        timer: 3000,
        icon: 'error',
        title: data.message,
        showConfirmButton: true,
      });
    }
  }

  async updateWithActionsAmount() {
    const actionsAmountFld = document.querySelector('#actions_amount');
    if (useBaseQuote(actionsAmountFld)) return;

    const formData = new FormData(this.formTarget);
    formData.delete('total_price');
    await this.updateActionQuote(formData);
  }

  async updateWithTotalPrice() {
    const totalPriceFld = document.querySelector('#total_price');
    if (useBaseQuote(totalPriceFld)) return;

    const formData = new FormData(this.formTarget);
    formData.delete('actions_amount');
    await this.updateActionQuote(formData);
  }

  async updateActionQuote(formData) {
    const actionsAmountFld = document.querySelector('#actions_amount');
    const totalPriceFld = document.querySelector('#total_price');
    if (!actionsAmountFld || !totalPriceFld) return;

    showHideLoading(true);
    const baseUrl = this.formTarget.action + '.json';
    const quoteUrl = this.getUrl(baseUrl, formData);
    const response = await fetch(quoteUrl, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
      },
    });
    let data;
    try {
      data = await response.json();
    } catch (error) {
      data = { message: 'Error al actualizar la cotización' };
      this.disableReserve();
    }
    this.validateQuote(data);
    const parsedTotalPrice = parseFloat(data.total_price) || 0;
    totalPriceFld.value = parsedTotalPrice.toFixed(2);
    actionsAmountFld.value = data.area;
    showHideLoading(false);
  }

  validateQuote(data) {
    this.validateActionsAmount(data);
  }

  validateActionsAmount(data) {
    const actionsAmountFld = document.querySelector('#actions_amount');
    const area = parseFloat(data.area) || 0;
    const actionsAvailable = parseFloat(data.actions_available) || 0;
    if (area <= 0 || area > actionsAvailable) {
      actionsAmountFld.classList.add('is-invalid');
      this.disableReserve();
      return;
    }
    actionsAmountFld.classList.remove('is-invalid');
    this.enableReserve();
  }

  disableReserve() {
    const saveBtn = document.querySelector('#save');
    const reserveBtn = document.querySelector('#reserve');
    const reserveAdminBtn = document.querySelector('#reserve_admin');
    const downloadPdfBtn = document.querySelector('#download_pdf');
    const sendEmailBtn = document.querySelector('#send_email');

    if (saveBtn) saveBtn.disabled = true;
    if (reserveBtn) reserveBtn.disabled = true;
    if (reserveAdminBtn) reserveAdminBtn.disabled = true;
    if (downloadPdfBtn) downloadPdfBtn.disabled = true;
    if (sendEmailBtn) sendEmailBtn.disabled = true;
  }

  enableReserve() {
    const saveBtn = document.querySelector('#save');
    const reserveBtn = document.querySelector('#reserve');
    const reserveAdminBtn = document.querySelector('#reserve_admin');
    const downloadPdfBtn = document.querySelector('#download_pdf');
    const sendEmailBtn = document.querySelector('#send_email');

    if (saveBtn) saveBtn.disabled = false;
    if (reserveBtn) reserveBtn.disabled = false;
    if (reserveAdminBtn) reserveAdminBtn.disabled = false;
    if (downloadPdfBtn) downloadPdfBtn.disabled = false;
    if (sendEmailBtn) sendEmailBtn.disabled = false;
  }
}
