import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

const TIMEOUT = 7500;
const REQUIRE_SUBSTITUTION = ['01', '04'];

export default class extends Controller {
  static targets = [
    'motive',
    'substitution',
    'form',
    'btnGenerateGlobalInvoice',
    'paymentForm',
    'percentageCoowner',
    'additionalConcept',
    'btnGenerateAdditionalConceptInvoice',
    'billingKeySelect',
    'btnGenerateInvoiceMultipleBillingKeys',
  ];

  changeMotive() {
    const input = this.substitutionTarget.querySelector('#substitution');
    if (REQUIRE_SUBSTITUTION.includes(this.motiveTarget.value)) {
      this.substitutionTarget.style.display = 'block';
      input.setAttribute('required', '');
    } else {
      this.substitutionTarget.style.display = 'none';
      input.removeAttribute('required');
    }
  }

  showLoading({ title, text }) {
    return new Promise(resolve => {
      Swal.fire({
        title,
        text,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
          resolve();
        },
      });
    });
  }

  showError(text) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      html: text,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      confirmButton: false,
      cancelButton: true,
      buttonsStyling: false,
    });
  }

  closeLoading() {
    Swal.close();
  }

  async getReport() {
    let body = {};

    if (this.hasPaymentFormTarget) {
      body.payment_form = this.paymentFormTarget.value;
    }
    if (this.hasPercentageCoownerTarget) {
      body.percentage_coowner = this.percentageCoownerTarget.value;
    }
    if (this.hasAdditionalConceptTarget) {
      body.billing_key_additional_concept = this.additionalConceptTarget.value;
    }
    if (this.hasBillingKeySelectTarget) {
      body.billing_key_id = this.billingKeySelectTarget.value;
    }

    const response = await fetch(this.data.get('url'), {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
    });

    if (response.ok) {
      return await response.json();
    }

    await Promise.reject(response);
  }

  async openFile(url) {
    await Swal.fire({
      title: 'Factura lista',
      text: 'Hemos terminado de generar tu factura...',
      showCancelButton: true,
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: 'Omitir descarga',
      confirmButtonText: 'Descargar factura',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ml-2',
      },
    }).then(result => {
      if (result.value) {
        const link = document.createElement('a');
        link.href = url;
        link.download = url
          .split('#')
          .shift()
          .split('?')
          .shift()
          .split('/')
          .pop();
        link.dispatchEvent(new MouseEvent('click'));
      }
    });
  }

  async send() {
    await this.showLoading({
      title: 'Enviando factura',
      text:
        'La factura será enviada al email del cliente y correos adicionales...',
    });

    try {
      const response = await fetch(this.data.get('url'), {
        method: 'POST',
        headers: {
          'X-CSRF-Token': Rails.csrfToken(),
        },
      });

      this.closeLoading();

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();

      if (!data.ok) {
        throw new Error(data.message);
      }

      Swal.fire({
        type: 'success',
        title: 'Factura enviada correctamente',
      });
    } catch (error) {
      console.error(error);
      this.showError(
        'Hubo un error al enviar la factura, por favor inténtalo de nuevo.'
      );
    }
  }

  async download(titleLoading) {
    await this.showLoading({
      title: titleLoading,
      text: 'La descarga se iniciará de manera automática...',
    });

    try {
      const response = await fetch(this.data.get('url'), {
        method: 'GET',
        headers: {
          'X-CSRF-Token': Rails.csrfToken(),
        },
      });

      this.closeLoading();

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const filename = response.headers
        .get('Content-Disposition')
        .split(';')[1]
        .split('=')[1]
        .replace(/['"]/g, '');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error(error);
      this.showError(
        'Hubo un error en la descarga, por favor inténtalo de nuevo.'
      );
    }
  }

  async downloadInvoice() {
    await this.download('Descargando factura');
  }

  async downloadCancellationReceipt() {
    await this.download('Descargando acuse de cancelación');
  }

  confirm(options) {
    return new Promise(resolve => {
      Swal.fire(options).then(result => resolve(result));
    });
  }

  async cancel() {
    await this.showLoading({
      title: 'Cancelando factura',
      text: 'Espere un momento mientras cancelamos la factura ...',
    });

    try {
      const substitution = this.substitutionTarget.querySelector(
        '#substitution'
      ).value;
      const response = await fetch(this.data.get('url'), {
        method: 'POST',
        body: JSON.stringify({
          motive: this.motiveTarget.value,
          ...(REQUIRE_SUBSTITUTION.includes(this.motiveTarget.value) &&
            substitution && { substitution }),
        }),
        headers: {
          'X-CSRF-Token': Rails.csrfToken(),
          'Content-Type': 'application/json',
        },
      });

      this.closeLoading();

      if (!response.ok) throw new Error(response.statusText);

      const { ok, message } = await response.json();
      await Swal.fire({
        type: ok ? 'success' : 'error',
        title: 'Cancelar factura',
        text: message,
      });
      if (ok) window.location.reload();
    } catch (error) {
      console.error(error);
      this.showError(
        'Hubo un error al cancelar la factura, por favor inténtalo de nuevo.'
      );
    }
  }

  async generate() {
    const result = await this.confirm({
      type: 'info',
      title: 'Generar factura',
      text:
        '¿Estás seguro que deseas generar la factura del pago seleccionado?',
      showCancelButton: true,
      confirmButtonText: 'Sí, generar',
      cancelButtonText: 'Cancelar',
    });
    if (result.value) {
      await this.showLoading({
        title: 'Generando factura',
        text: 'En cuanto esté disponible, verás el botón de descarga...',
      });
      try {
        const job_status = await this.getReport();
        const file_url = await this.downloadItemWithTimeout(job_status.url);
        this.closeLoading();
        await this.openFile(file_url);
        window.location.reload();
      } catch (err) {
        console.warn(err);
        this.showError(
          `
          Hubo un error al generar la factura, por favor inténtalo de nuevo. <br/>
          ${err}
          `
        );
      }
    }
  }

  validateGlobalInvoiceFields() {
    let valid_fields = this.paymentFormTarget.checkValidity();
    if (this.hasPercentageCoownerTarget) {
      valid_fields = this.percentageCoownerTarget.checkValidity();
    }
    if (this.hasBillingKeySelectTarget) {
      valid_fields = this.billingKeySelectTarget.checkValidity();
    }
    this.btnGenerateGlobalInvoiceTarget.disabled = !valid_fields;
  }

  changeAdditionalConcept() {
    this.btnGenerateAdditionalConceptInvoiceTarget.disabled = !this
      .additionalConceptTarget.value;
  }

  async generate_global_invoice() {
    if (!this.paymentFormTarget.value) {
      return;
    }

    const result = await this.confirm({
      type: 'info',
      title: 'Generar factura global',
      text: '¿Estás seguro que deseas generar la factura de los pagos?',
      showCancelButton: true,
      confirmButtonText: 'Sí, generar',
      cancelButtonText: 'Cancelar',
    });
    if (result.value) {
      await this.showLoading({
        title: 'Generando factura global',
        text: 'En cuanto esté disponible, verás el botón de descarga...',
      });
      try {
        const job_status = await this.getReport();
        const file_url = await this.downloadItemWithTimeout(job_status.url);
        this.closeLoading();
        await this.openFile(file_url);
        window.location.reload();
      } catch (err) {
        console.warn(err);
        this.showError(
          `
          Hubo un error al generar la factura, por favor inténtalo de nuevo. <br/>
          ${err}
          `
        );
      }
    }
  }

  async generate_additional_concept_invoice() {
    if (!this.additionalConceptTarget.value) {
      return;
    }

    const result = await this.confirm({
      type: 'info',
      title: 'Generar factura servicio adicional',
      text:
        '¿Estás seguro que deseas generar la factura para el servicio adicional?',
      showCancelButton: true,
      confirmButtonText: 'Sí, generar',
      cancelButtonText: 'Cancelar',
    });
    if (result.value) {
      await this.showLoading({
        title: 'Generando factura servicio adicional',
        text: 'En cuanto esté disponible, verás el botón de descarga...',
      });
      try {
        const job_status = await this.getReport();
        const file_url = await this.downloadItemWithTimeout(job_status.url);
        this.closeLoading();
        await this.openFile(file_url);
        window.location.reload();
      } catch (err) {
        console.warn(err);
        this.showError(
          `
          Hubo un error al generar la factura, por favor inténtalo de nuevo. <br/>
          ${err}
          `
        );
      }
    }
  }

  changeBillingKeyId() {
    this.btnGenerateInvoiceMultipleBillingKeysTarget.disabled = !this
      .billingKeySelectTarget.value;
  }

  async generateInvoiceMultipleBillingKeys() {
    if (!this.billingKeySelectTarget.value) {
      return;
    }

    const result = await this.confirm({
      type: 'info',
      title: 'Generar factura',
      text:
        '¿Estás seguro que deseas generar la factura con el concepto seleccionado?',
      showCancelButton: true,
      confirmButtonText: 'Sí, generar',
      cancelButtonText: 'Cancelar',
    });
    if (result.value) {
      await this.showLoading({
        title: 'Generando factura',
        text: 'En cuanto esté disponible, verás el botón de descarga...',
      });
      try {
        const job_status = await this.getReport();
        const file_url = await this.downloadItemWithTimeout(job_status.url);
        this.closeLoading();
        await this.openFile(file_url);
        window.location.reload();
      } catch (err) {
        console.warn(err);
        this.showError(
          `
          Hubo un error al generar la factura, por favor inténtalo de nuevo. <br/>
          ${err}
          `
        );
      }
    }
  }

  async generate_payment_complement() {
    Swal.close();
    const result = await this.confirm({
      type: 'info',
      title: 'Generar complemento de pago',
      text: '¿Estás seguro que deseas el complemento de pago?',
      showCancelButton: true,
      confirmButtonText: 'Sí, generar',
      cancelButtonText: 'Cancelar',
    });
    if (result.value) {
      await this.showLoading({
        title: 'Generando complemento de pago',
        text: 'En cuanto esté disponible, verás el botón de descarga...',
      });
      try {
        const job_status = await this.getReport();
        const file_url = await this.downloadItemWithTimeout(job_status.url);
        this.closeLoading();
        await this.openFile(file_url);
        window.location.reload();
      } catch (err) {
        console.warn(err);
        this.showError(
          `
          Hubo un error al generar el complemento de pago, por favor inténtalo de nuevo. <br/>
          ${err}
          `
        );
      }
    }
  }

  async reinvoice() {
    const result = await this.confirm({
      type: 'info',
      title: 'Re-facturar',
      text:
        '¿Estás seguro que deseas el re-facturar todas las facturas ya generadas?',
      showCancelButton: true,
      confirmButtonText: 'Sí, re-facturar',
      cancelButtonText: 'Cancelar',
    });
    if (result.value) {
      await this.showLoading({ title: 'Re-facturando...' });
      try {
        const job_status = await this.getReport();
        const { status, error_message } = await this.poll(
          job_status.url,
          TIMEOUT
        );

        if (status !== 'success') {
          throw new Error(error_message);
        }

        this.closeLoading();

        Swal.fire({
          type: 'success',
          title: 'Se ha re-facturado correctamente.',
        }).then(result => {
          window.location.reload();
        });
      } catch (err) {
        console.warn(err);
        const error = err.error_message ? err.error_message : err;
        this.showError(
          `
          Hubo un error al re-facturar, por favor inténtalo de nuevo. <br/>
          ${error}
          `
        );
      }
    }
  }

  async wait(time) {
    return await new Promise(resolve => setTimeout(resolve, time));
  }

  async poll(url, interval) {
    return new Promise((resolve, reject) => {
      const poller = setInterval(async () => {
        try {
          const response = await fetch(url);

          if (!response.ok) {
            throw new Error(response.statusText);
          }

          const { status, error_message = '' } = await response.json();

          if (status === 'success') {
            clearInterval(poller);
            resolve({ status });
          } else if (status === 'error') {
            clearInterval(poller);
            reject({ status, error_message });
          }
        } catch (error) {
          clearInterval(poller);
          reject(error);
        }
      }, interval);
    });
  }

  async downloadItem(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const job_status = await response.json();
    if (job_status.document) {
      return job_status.document;
    } else if (job_status.retry) {
      throw new Error('Retry');
    }
    if (job_status.error_message) {
      const error_message = this.isJson(job_status.error_message)
        ? JSON.parse(job_status.error_message).message
        : job_status.error_message;
      throw new Error(error_message);
    } else {
      throw new Error('Intentalo de nuevo');
    }
  }

  async downloadItemWithTimeout(url) {
    try {
      return await this.downloadItem(url);
    } catch (err) {
      if (err.message && err.message === 'Retry') {
        await this.wait(TIMEOUT);
        return await this.downloadItemWithTimeout(url);
      }
      throw err;
    }
  }

  isJson(string_json) {
    try {
      JSON.parse(string_json);
    } catch (e) {
      return false;
    }
    return true;
  }
}
