import { Controller } from 'stimulus';
import consumer from '../channels/consumer';
import {
  showEmojiPicker,
  messageEvents,
  scrollToBottom,
  insertMessage,
  subscribeToEvents,
  deleteSubscriptions,
  showErrorAlert,
} from '../helpers/conversations';

export default class extends Controller {
  connect() {
    if (!this.data.get('clientId') || !this.data.get('clientPhoneNumber')) {
      return;
    }

    this.webhookIds = [];
    this.subscribeToChannel();
  }

  disconnect() {
    this.unsubscribeFromChannel();
  }

  subscribeToChannel() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'ConversationChannel',
        client_phone_number: this.data.get('clientPhoneNumber'),
      },
      {
        connected: async () => {
          showEmojiPicker();
          scrollToBottom();
          messageEvents(
            this.data.get('clientId'),
            this.data.get('clientPhoneNumber')
          );
          this.webhookIds = await subscribeToEvents(this.data.get('clientId'));
        },

        disconnected: async () => {
          await deleteSubscriptions(this.data.get('clientId'), this.webhookIds);
        },

        received: ({ message }) => {
          insertMessage(message);
        },
      }
    );

    this.setupWebSocketErrorHandlers();
  }

  unsubscribeFromChannel() {
    if (!this.subscription) return;

    this.subscription.unsubscribe();
  }

  setupWebSocketErrorHandlers() {
    const { connection } = this.subscription.consumer;

    connection.onerror = event => {
      console.error('Error en la conexión WebSocket:', event);
      showErrorAlert('Error al conectarse al chat.');
    };

    connection.onclose = event => {
      console.warn('La conexión WebSocket se cerró inesperadamente:', event);
    };
  }
}
