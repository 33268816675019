import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.openHandler = this.open.bind(this);
    this.hiddenHandler = this.cleanupModalContent.bind(this);

    document.addEventListener('modal:open', this.openHandler);
    $(this.element).on('hidden.bs.modal', this.hiddenHandler);
  }

  disconnect() {
    document.removeEventListener('modal:open', this.openHandler);
    $(this.element).off('hidden.bs.modal', this.hiddenHandler);
  }

  open() {
    if (!this.modalInstance) {
      this.modalInstance = new bootstrap.Modal(this.element, {
        keyboard: true,
        backdrop: true,
      });
    }
    this.modalInstance.show();
  }

  close(event) {
    event.preventDefault();
    if (this.modalInstance) this.modalInstance.hide();
  }

  cleanupModalContent() {
    const modalContainer = document.querySelector('#modal-container');
    if (modalContainer) modalContainer.innerHTML = '';
    this.element.remove();
    this.modalInstance = null;
  }
}
