import { Controller } from 'stimulus';

export default class extends Controller {
  projectChange() {
    this.buildTargetField(
      '/filters/phases?id=' + this.element.value,
      this.element.getAttribute('data-target-phases'),
      this.element.getAttribute('data-first-label')
    );
    this.cleanTargetBox(this.element.getAttribute('data-target-stages'));
    this.cleanTargetBox(this.element.getAttribute('data-target-lots'));
  }

  phasesChange() {
    this.buildTargetField(
      '/filters/stages?id=' + this.element.value,
      this.element.getAttribute('data-target-stages'),
      this.element.getAttribute('data-first-label')
    );
    this.cleanTargetBox(this.element.getAttribute('data-target-lots'));
  }

  stagesChange() {
    const can_with_only_folders = this.element.getAttribute(
      'data-can-with-only-folders'
    );
    this.buildTargetField(
      `/filters/lots?id=${this.element.value}&can_with_only_folders=${can_with_only_folders}`,
      this.element.getAttribute('data-target-lots'),
      this.element.getAttribute('data-first-label')
    );
  }

  buildTargetField(url, target_box_id, first_label) {
    const target_box = document.getElementById(target_box_id);
    this.initializeTargetBox(target_box, first_label);

    fetch(url, {})
      .then(response => response.json())
      .then(data => this.populateTargetBox(data, target_box));
  }

  initializeTargetBox(target_box, first_label) {
    target_box.innerHTML = '';
    if (first_label != '') {
      let option = document.createElement('option');
      option.value = '';
      option.innerHTML = first_label;
      target_box.appendChild(option);
    }
    target_box.disabled = false;
  }

  populateTargetBox(data, target_box) {
    data.forEach(item => {
      let option = document.createElement('option');
      option.value = item[0];
      option.innerHTML = item[1];
      target_box.appendChild(option);
    });
  }

  searchFolder(id, search_from, only_id, callbackFunction) {
    fetch(
      `/filters/search_folder?id=${id}&search_from=${search_from}&only_id=${only_id}`,
      {}
    )
      .then(response => response.json())
      .then(data => callbackFunction(data));
  }

  lotChangeSearchFolder() {
    const folder_field_id = this.element.getAttribute('data-target-folder');
    let folder_field = document.getElementById(folder_field_id);
    folder_field.value = '';
    folder_field.innerHTML = '';
    this.searchFolder(this.element.value, 'lot', true, data => {
      if (data['folder_id'] != '') {
        folder_field.value = data['folder_id'];
        folder_field.dispatchEvent(new Event('custom'));
      }
    });
  }

  folderChangeSearchClientsAndBankAccount() {
    this.buildTargetField(
      `/filters/clients?id=${this.element.value}&search_from=folder`,
      this.element.getAttribute('data-target-clients'),
      ''
    );
    this.buildTargetField(
      `/filters/bank_accounts?id=${this.element.value}&search_from=folder`,
      this.element.getAttribute('data-target-bank-accounts'),
      ''
    );
  }

  folderChangeSearchData() {
    this.folderChangeSearchClientsAndBankAccount();

    this.searchFolder(this.element.value, 'folder', false, data => {
      const target_amount = this.element.getAttribute('data-target-amount');
      let amount_field = document.getElementById(target_amount);
      amount_field.removeAttribute('max');
      if (data['total_remaining_amount'] != '') {
        amount_field.setAttribute('max', data['total_remaining_amount']);
      }

      if (data['project_id']) {
        this.updateProjectFields(data);
      }
    });
  }

  updateProjectFields(data) {
    let project_field = document.getElementById(
      this.element.getAttribute('data-target-projects')
    );
    if (project_field) {
      project_field.value = data['project_id'];
      project_field.dispatchEvent(new Event('change'));

      setTimeout(() => this.updatePhaseField(data), 500);
    }
  }

  updatePhaseField(data) {
    let phase_field = document.getElementById(
      this.element.getAttribute('data-target-phases')
    );
    if (phase_field) {
      phase_field.value = data['phase_id'];
      phase_field.dispatchEvent(new Event('change'));

      setTimeout(() => this.updateStageField(data), 700);
    }
  }

  updateStageField(data) {
    let stage_field = document.getElementById(
      this.element.getAttribute('data-target-stages')
    );
    if (stage_field) {
      stage_field.value = data['stage_id'];
      stage_field.dispatchEvent(new Event('change'));

      setTimeout(() => this.updateLotField(data), 900);
    }
  }

  updateLotField(data) {
    let lot_field = document.getElementById(
      this.element.getAttribute('data-target-lots')
    );
    if (lot_field) {
      lot_field.value = data['lot_id'];
    }
  }

  cleanTargetBox(target_box_id) {
    let target_box = document.getElementById(target_box_id);
    if (target_box) {
      target_box.value = '';
      target_box.innerHTML = '';
    }
  }
}
