import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['reassignmentType', 'userIds', 'userRoles'];

  connect() {
    $(
      '#automatic_reassignment_setting_reassignment_type, #automatic_reassignment_setting_user_roles'
    ).on('select2:select', function() {
      let event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });
  }

  async changeReassignmentType() {
    const showUsersSelect =
      this.reassignmentTypeTarget.value ===
      this.data.get('users-manually-selected');
    this.userIdsTarget.required = showUsersSelect;
    this.userIdsTarget.closest('.form-group').hidden = !showUsersSelect;
    if (this.userRolesTarget.selectedOptions) {
      await this.getUsers(
        Array.from(this.userRolesTarget.selectedOptions).map(
          ({ value }) => value
        )
      );
    }
  }

  async changeUserRoles() {
    await this.getUsers(
      Array.from(this.userRolesTarget.selectedOptions).map(({ value }) => value)
    );
  }

  async getUsers(role_ids) {
    try {
      const url = `${this.data.get('url-users-options')}?${new URLSearchParams({
        role_ids,
      })}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': Rails.csrfToken(),
        },
      });

      this.userIdsTarget.innerText = null;

      const users = await response.json();
      users.forEach(user =>
        this.userIdsTarget.add(
          new Option(user.label, user.id, null, user.selected)
        )
      );
    } catch (e) {
      console.error(e);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al obtener los usuarios.',
      });
    }
  }
}
