import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

const TIMEOUT = 7500;

export default class extends Controller {
  confirm(options) {
    return new Promise(resolve => {
      Swal.fire(options).then(result => resolve(result));
    });
  }

  showLoading({ title, text }) {
    return new Promise(resolve => {
      Swal.fire({
        title,
        text,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
          resolve();
        },
      });
    });
  }

  showError(text) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      html: text,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      confirmButton: false,
      cancelButton: true,
      buttonsStyling: false,
    });
  }

  closeLoading() {
    Swal.close();
  }

  closeModal() {
    document.querySelector('#sendFolderFilesModal').nextElementSibling.click();
  }

  async wait(time) {
    return await new Promise(resolve => setTimeout(resolve, time));
  }

  isJson(string_json) {
    try {
      JSON.parse(string_json);
    } catch (e) {
      return false;
    }
    return true;
  }

  async checkStatusProcess(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const job_status = await response.json();

    if (job_status.status === 'success') {
      return;
    }

    if (job_status.retry) {
      throw new Error('Retry');
    }

    if (job_status.error_message) {
      const error_message = this.isJson(job_status.error_message)
        ? JSON.parse(job_status.error_message).message
        : job_status.error_message;
      throw new Error(error_message);
    } else {
      throw new Error('Intentalo de nuevo');
    }
  }

  async checkStatusProcessWithTimeout(url) {
    try {
      return await this.checkStatusProcess(url);
    } catch (err) {
      if (err.message && err.message === 'Retry') {
        await this.wait(TIMEOUT);
        return await this.checkStatusProcessWithTimeout(url);
      }
      throw err;
    }
  }

  async send({
    document_type,
    annexe_template_id,
    recipients,
    additional_emails,
  }) {
    const response = await fetch(this.data.get('url'), {
      method: 'POST',
      body: JSON.stringify({
        document_type,
        annexe_template_id,
        recipients,
        additional_emails,
      }),
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return await response.json();
    }

    await Promise.reject(response);
  }

  async confirmSend(event) {
    event.preventDefault();

    const formData = new FormData(this.element);
    const data = Object.fromEntries(formData.entries());

    if (!data.recipients && !data.additional_emails) return;

    try {
      await this.showLoading({
        title: 'Enviando archivo',
        text: 'Te avisaremos una vez se haya concluido el envio...',
      });

      const job_status = await this.send(data);
      await this.checkStatusProcessWithTimeout(job_status.url);

      this.closeLoading();
      this.closeModal();

      await Swal.fire({
        title: '¡Éxito!',
        text: 'Archivo enviando correctamente',
        type: 'success',
        confirmButtonText: 'Ok',
      });
    } catch (e) {
      console.warn(e);
      this.showError(
        `Hubo un error al enviar el archivo, por favor inténtalo de nuevo. <br/> ${e}`
      );
    }
  }
}
