import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['mandatorySelect', 'optionalSelect'];

  async connect() {
    await this.filterAdditionalConceptsOptions();

    $(this.mandatorySelectTarget).on('select2:selecting', () =>
      this.filterAdditionalConceptsOptions()
    );
    $(this.mandatorySelectTarget).on('select2:unselecting', () =>
      this.filterAdditionalConceptsOptions()
    );
    $(this.optionalSelectTarget).on('select2:selecting', () =>
      this.filterAdditionalConceptsOptions()
    );
    $(this.optionalSelectTarget).on('select2:unselecting', () =>
      this.filterAdditionalConceptsOptions()
    );
  }

  async filterAdditionalConceptsOptions() {
    try {
      const result = await fetch(
        '/additional_concepts/additional_concepts_options_on_credit'
      );
      const { additional_concepts } = await result.json();

      const mandatorySelected = Array.from(
        this.mandatorySelectTarget.selectedOptions
      ).map(option => Number(option.value));
      const optionalSelected = Array.from(
        this.optionalSelectTarget.selectedOptions
      ).map(option => Number(option.value));

      this.mandatorySelectTarget.innerHTML = '';
      this.optionalSelectTarget.innerHTML = '';

      additional_concepts.forEach(additional_concept => {
        const newOption = new Option(
          additional_concept.name,
          additional_concept.id
        );
        if (mandatorySelected.includes(additional_concept.id)) {
          newOption.selected = true;
          this.mandatorySelectTarget.appendChild(newOption);
        } else if (!optionalSelected.includes(additional_concept.id)) {
          newOption.selected = false;
          this.mandatorySelectTarget.appendChild(newOption);
        }
      });

      additional_concepts.forEach(additional_concept => {
        const newOption = new Option(
          additional_concept.name,
          additional_concept.id
        );
        if (optionalSelected.includes(additional_concept.id)) {
          newOption.selected = true;
          this.optionalSelectTarget.appendChild(newOption);
        } else if (!mandatorySelected.includes(additional_concept.id)) {
          newOption.selected = false;
          this.optionalSelectTarget.appendChild(newOption);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
}
