import Swal from 'sweetalert2';
import { Picker } from 'emoji-picker-element';
import es from 'emoji-picker-element/i18n/es';
import * as Popper from '@popperjs/core';
import * as textFieldEdit from 'text-field-edit';

let fileToSend = null;

export function showErrorAlert(message) {
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    html: message,
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    confirmButton: false,
    cancelButton: true,
    buttonsStyling: false,
  });
}

export function scrollToBottom() {
  const chatContainer = document.querySelector('.conversation-container');
  if (!chatContainer) return;
  chatContainer.scrollTop = chatContainer.scrollHeight;
}

export function showEmojiPicker() {
  const emojiButton = document.querySelector('.emoji');
  const emojiPopper = document.querySelector('.emoji-popper');

  if (!emojiButton || !emojiPopper) return;

  const picker = new Picker({
    i18n: es,
    locale: 'es',
    emojiVersion: 17.0,
  });
  picker.classList.add('light');
  emojiPopper.appendChild(picker);

  Popper.createPopper(emojiButton, emojiPopper);

  emojiButton.addEventListener('click', () => {
    emojiPopper.classList.toggle('shown');
  });

  document.addEventListener('click', event => {
    if (
      !emojiPopper.contains(event.target) &&
      !emojiButton.contains(event.target)
    ) {
      emojiPopper.classList.remove('shown');
    }
  });

  const emojiPicker = document.querySelector('emoji-picker');
  if (!emojiPicker) return;

  emojiPicker.addEventListener('emoji-click', event => {
    const messageInput = document.querySelector('#messageValue');
    textFieldEdit.insert(messageInput, event.detail.unicode);
    emojiPopper.classList.remove('shown');
  });
}

export function insertMessage(messageHtml) {
  const chatContainer = document.querySelector(
    '[data-controller="conversation"] .conversation-container #ap'
  );
  if (!chatContainer) return;

  chatContainer.insertAdjacentHTML('beforeend', messageHtml);
  scrollToBottom();
}

export function messageEvents(clientId, clientPhoneNumber) {
  const messageForm = document.querySelector('#messageForm');
  if (!messageForm) return;

  messageForm.addEventListener('submit', async e => {
    e.preventDefault();

    const messageInput = document.querySelector('#messageValue');
    if (!messageInput.value && !fileToSend) return;

    await sendMessage(clientId, messageInput.value, fileToSend);
  });

  const modalMessageForm = document.querySelector('#modalMessageForm');
  if (!modalMessageForm) return;

  modalMessageForm.addEventListener('submit', async e => {
    e.preventDefault();

    const form = e.target;
    const fileToSendLabel = form.querySelector('[for="file"]');
    const fileToSendInput = form.querySelector('#fileToSend');

    const file = fileToSendInput.files[0];
    fileToSend = file;
    handleFile(file);

    fileToSendLabel.textContent = 'Seleccionar archivo';
    fileToSendInput.value = '';
    form.reset();

    document
      .querySelector('#sendFileModal')
      .querySelector('.modal-header .close')
      .click();
  });

  let pageNumber = 0;
  let isLoading = false;
  const conversationContainer = document.querySelector(
    '.conversation-container'
  );
  if (!conversationContainer) return;

  conversationContainer.addEventListener('scroll', async () => {
    if (conversationContainer.scrollTop !== 0 || isLoading) return;

    isLoading = true;

    const previousScrollHeight = conversationContainer.scrollHeight;
    const messages = await getMessages(
      clientId,
      clientPhoneNumber,
      pageNumber + 1
    );

    if (!messages || messages.length === 0) {
      isLoading = false;
      return;
    }

    pageNumber++;
    messages.forEach(message => {
      conversationContainer.insertAdjacentHTML('afterbegin', message);
    });

    const newScrollHeight = conversationContainer.scrollHeight;
    conversationContainer.scrollTop += newScrollHeight - previousScrollHeight;
  });
}

export async function getMessages(clientId, clientPhoneNumber, pageNumber) {
  try {
    const url = new URL(
      `/clients/${clientId}/conversations/get_messages`,
      window.location.origin
    );
    url.search = new URLSearchParams({
      client_phone_number: clientPhoneNumber,
      page_number: pageNumber,
    }).toString();

    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
    });

    if (!result.ok) throw new Error(`Error: ${result.status}`);

    const { messages } = await result.json();
    return messages;
  } catch (e) {
    console.error(e);
    showErrorAlert('No se pudieron cargar los mensajes');
  }
}

export async function sendMessage(clientId, message, file = null) {
  try {
    const formData = new FormData();
    formData.append('message', message);
    if (file) formData.append('file', file);

    const result = await fetch(
      `/clients/${clientId}/conversations/send_message`,
      {
        method: 'POST',
        headers: {
          'X-CSRF-Token': Rails.csrfToken(),
        },
        body: formData,
      }
    );

    if (!result.ok) throw new Error(`Error: ${result.status}`);

    const { success } = await result.json();
    if (!success) throw new Error('Error al enviar el mensaje');

    const messageForm = document.querySelector('#messageForm');
    if (messageForm) messageForm.reset();

    removeFile();
  } catch (e) {
    console.error(e);
    showErrorAlert('No se pudo enviar el mensaje');
  }
}

export async function subscribeToEvents(clientId) {
  const events = ['whatsapp.message.sent', 'whatsapp.message.received'];
  const webhookIds = [];

  Swal.fire({
    title: 'Subscribiendo a eventos...',
    allowOutsideClick: false,
    allowEscapeKey: false,
    onBeforeOpen: () => Swal.showLoading(),
  });

  try {
    for (const event of events) {
      const result = await fetch(
        `/clients/${clientId}/conversations/subscribe_to_event`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
          body: JSON.stringify({ event }),
        }
      );

      if (!result.ok) throw new Error(`Error: ${result.status}`);

      const { webhook_id } = await result.json();
      if (webhook_id) webhookIds.push(webhook_id);
    }

    Swal.close();
    return webhookIds;
  } catch (e) {
    console.error(e);
    Swal.close();
    showErrorAlert(
      'Hubo un error al suscribirse a los eventos. Por favor, recarga la página e intenta nuevamente.'
    );
  }
}

export async function deleteSubscriptions(clientId, webhookIds) {
  try {
    const result = await fetch(
      `/clients/${clientId}/conversations/delete_subscription`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken(),
        },
        body: JSON.stringify({ webhook_ids: webhookIds }),
      }
    );

    if (!result.ok) throw new Error(`Error: ${result.status}`);
  } catch (e) {
    console.error(e);
  }
}

function handleFile(file) {
  if (!file) return;

  const messageForm = document.querySelector('#messageForm');
  let previewFiles = getOrCreatePreviewFiles();
  let previewContainer = getOrCreatePreviewContainer(previewFiles);

  if (previewContainer.children.length > 0) previewContainer.innerHTML = '';

  const fileElement = createFileElement(file);
  previewContainer.prepend(fileElement);

  previewFiles.appendChild(previewContainer);
  messageForm.prepend(previewFiles);
}

function removeFile() {
  const previewFilesElement = document
    .querySelector('#messageForm')
    .querySelector('.preview-files');
  if (previewFilesElement) previewFilesElement.remove();
}

function getOrCreatePreviewFiles() {
  const messageForm = document.querySelector('#messageForm');
  let previewFiles = messageForm.querySelector('.preview-files');
  if (previewFiles) return previewFiles;

  previewFiles = document.createElement('div');
  previewFiles.className = 'preview-files';
  return previewFiles;
}

function getOrCreatePreviewContainer(previewFiles) {
  let previewContainer = previewFiles.querySelector('.preview-files-container');
  if (previewContainer) return previewContainer;

  previewContainer = document.createElement('div');
  previewContainer.className = 'preview-files-container';
  return previewContainer;
}

function createFileElement(file) {
  const fileWrapper = document.createElement('div');

  const fileButton = document.createElement('button');
  fileButton.type = 'button';
  fileButton.className = 'btn btn-outline-primary btn-sm';
  fileButton.textContent = file.name;
  fileButton.innerHTML = `${file.name} <i class="fa fa-trash"></i>`;

  fileButton.addEventListener('click', () => removeFileElement());

  fileWrapper.appendChild(fileButton);

  return fileWrapper;
}

function removeFileElement() {
  Swal.fire({
    type: 'warning',
    title: '¿Estás seguro?',
    text: '¿Quieres eliminar el archivo?',
    showCancelButton: true,
    confirmButtonText: 'Sí',
    cancelButtonText: 'No',
  }).then(({ value }) => {
    if (!value) return;
    fileToSend = null;
    removeFile();
  });
}
